import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { storage } from '../../firebase/init';

function ListItem(props:any) {

    return (
        <Item to={`/portfolio/view?id=${props.item.id}`}>
            <Thumb>
                <Img src={props.item.image.src} alt={props.item.image.name} />
            </Thumb>
        </Item>
    )
}

const Item = styled(Link)`
    display:inline-block;
    vertical-align:top;
    margin-bottom:1em;
    max-height:300px;
    overflow:hidden;

    &:hover, &:focus {
        background:#37373d;
    }
`;

const Thumb = styled.div`
    position:relative;
    background:#101010;
`;

const Img = styled.img`
    width:100%;
`;


export default ListItem;

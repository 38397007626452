import { createReducer } from "typesafe-actions";
import { GET_POSTS, LIKE_POSTS } from "./actions";
import { PostsState, PostsAction } from "./types";

const initialState:PostsState = null;

const onGetPosts = createReducer<PostsState, PostsAction>(initialState, {
    [GET_POSTS]: (state, action) => (
        action.payload
    ),
    [LIKE_POSTS]: (state, action) => (
        state
    )
});

export default onGetPosts;
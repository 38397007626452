import { createReducer } from "typesafe-actions";
import { SET_POSTS, DEL_POSTS } from "./actions";
import { PostsState, PostsAction } from "./types";

const initialState:PostsState = {
    title:"",
    content:"",
    type:""
};

const onSetPosts = createReducer<PostsState, PostsAction>(initialState, {
    [SET_POSTS]: (state, action) => (
        action.payload
    ),
    [DEL_POSTS]: (state, action) => (
        action.payload
    )
});

export default onSetPosts;
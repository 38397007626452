import { createReducer } from "typesafe-actions";
import { GET_PORTFOLIO } from "./actions";
import { PortfolioState, PortfolioAction } from "./types";

const initialState:PortfolioState = null;

const getPortfolio = createReducer<PortfolioState, PortfolioAction>(initialState, {
    [GET_PORTFOLIO]: (state, action) => (
        action.payload
    )
})

export default getPortfolio;
import React, { useEffect } from 'react';
import qs from 'qs';

//use hooks
import useGetPortfolio from '../../hooks/useGetPortfolio';
import useSetPortfolio from '../../hooks/useSetPortfolio';
import useSidenav from '../../hooks/useSidenav';
import useAuth from '../../hooks/useAuth';

//import modules
import { ContentTheme, TitleTheme } from '../../Theme';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BsTrashFill, BsTools, BsFillDisplayFill } from 'react-icons/bs';

//import components
import SideNav from '../SideNavgation';

//import resource
import Icon from '../../images/ico_web_32.png';

function View(props:any) {

    const { list, onGetPortfolio } = useGetPortfolio();
    const { form, onDelPortfolio } = useSetPortfolio();
    const { show } = useSidenav();
    const { user } = useAuth();

    const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true 
     });

    useEffect(() => {
        onGetPortfolio();
    }, []);

    const onDelPortfolioHandler = () => {
        if(window.confirm("정말로 삭제하시겠습니까?")) onDelPortfolio(query.id);
    }

    return (
        <>
            <SideNav list={list} uri="portfolio" id={query.id?.toString()} />
            {list ?
                list.filter((row) => {
                    return row.id === query.id;
                }).map((item, index) => {
                    return <Content id="content" key={index} {...show}>
                        <Title>
                            {item.title}
                            <Date>Production year : {item.year}</Date>
                            
                            <ButtonWrap>
                                {item.url ? <WebSite href={item.url} target="_blank" title="새창열림" rel="noreferrer noopener"><BsFillDisplayFill size={20}/></WebSite> : null}
                                {user.status ? 
                                    <>
                                        <FormButton to={`/portfolio/form?id=${query.id}`}><BsTools size={20}/></FormButton>
                                        <DelButton onClick={onDelPortfolioHandler}><BsTrashFill size={20}/></DelButton>
                                    </>
                                : null}
                            </ButtonWrap>
                        </Title>
                        <Sumry>
                            {item.content.split("\n").map((char:string, idx:number) => {
                                return (
                                    <p key={idx}>{char}<br/></p>
                                )
                            })}
                        </Sumry>
                        <Img src={item.image.src} alt={item.image.name}></Img>
                        
                    </Content>
                })
            : null}
        </>
    )
}

const Content = styled.div`
    ${ContentTheme}
`;

const Title = styled.h2`
    ${TitleTheme}
`;

const Date = styled.p`
    font-size: 0.6em;
    margin-top:0.5em;
    font-weight:normal;
`;

const Sumry = styled.div`
    font-size:1em;
    margin-bottom:1em;
`;

const ButtonWrap = styled.div`
    display:inline-block;
    position:absolute;
    bottom:1em;
    right:0;
    font-size:0.6em;
    font-weight:normal;
`;

const FormButton = styled(Link)`
    margin-left:1em;
`;

const DelButton = styled.button`
    color:inherit;
    margin-left:1em;
`;

const WebSite = styled.a`
`;

const WebIcon = styled.img`
    width:1.5em;
    height:1.5em;
    vertical-align: middle;
    margin-right:0.5em;
`;

const Img = styled.img`

`;

export default View;

import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../images/logo.png';
import useAuth from '../hooks/useAuth';
import useGetPosts from '../hooks/useGetPosts';
import useSetPosts from '../hooks/useSetPosts';
import { BsFillLightningFill } from 'react-icons/bs';


function Navigation(){

    const { user, onAuthIn, onAuthOut, onAuthState } = useAuth();
    const { list } = useGetPosts();
    const { form } = useSetPosts();

    useEffect(() => {
        if(!user.status){
            onAuthState();
        }
    }, []);

    const onAuthOutHandler = () => {
        onAuthOut();
    }

    return (
        <Nav>
            <Image src={Logo} alt=""/>
            <Menu>
                <Link exact to="/"><Strong>Home</Strong></Link>
                <Link to="/portfolio"><Strong>Portfolio</Strong></Link>
                <Link to="/about"><Strong>About</Strong></Link>
                <Link to="/posts"><Strong>Posts</Strong></Link>
                {/* <button onClick={() => console.log(list)}>테스트</button> */}
            </Menu>
            {/* <Theme>
                <Button>Dark</Button>
            </Theme> */}
            {!user.status ?
                <LoginBtn to="/auth"><span><BsFillLightningFill size={25}/></span></LoginBtn>
                :
                <LogoutBtn onClick={onAuthOutHandler}><BsFillLightningFill size={25} color="#ffd400"/></LogoutBtn>
            }
            
        </Nav>
    )
}

const Nav = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    height:60px;
    background:#1e1e1e;
    border-bottom:1px solid #383838;
    z-index:99;
    &:after {
        content:"";
        display:block;
        clear:both;
    }
`;

const Image = styled.img`
    position:absolute;
    top:50%;
    left: 1em;
    transform: translateY(-50%);
    height: 30px;
`;

const Menu = styled.div`
    display:table;
    height:inherit;
    padding-left:calc(2em + 50px);
`;

const Link = styled(NavLink)`
    display:table-cell;
    vertical-align:middle;
    padding:0 15px;
    &.active {
        color:#007acc;
    }
`;

const Strong = styled.strong`

`;

const Theme = styled.div`
    position:absolute;
    top:50%; right:1em;
    transform: translateY(-50%);
    font-size:0.8em;
`;

const Button = styled.button`
    width:6em;
    height:2em;
    background:rgba(0,0,0,.5);
    border-radius:1em;

    &:before {
        content:"";
        display:block;
        background:rgba(255,255,255,.3);
        border-radius:50%;
        width:1.5em;
        height:1.5em;
        position:absolute;
        top:50%;
        left:0.25em;
        transform: translateY(-50%);
        transition: all 0.5s ease;
    }
`;

const LoginBtn = styled(NavLink)`
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    color:#bababa;
    font-size:0.9em;
    width:60px;
    text-align:center;
    background:#333;
    overflow:hidden;

    & > span {
        position:absolute;
        top:50%;
        left:0;
        right:0;
        transform: translateY(-50%);
    }
`;

const LogoutBtn = styled.button`
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    color:#bababa;
    font-size:0.9em;
    width:60px;
    text-align:center;
    background:#333;
    overflow:hidden;
`;

export default Navigation;
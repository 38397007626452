import { combineReducers } from 'redux';
import getPortfolio from './getPortfolio';
import setPortfolio from './setPortfolio';
import getPosts from './getPosts';
import setPosts from './setPosts';
import sidenav from './sidenav';
import auth from './auth';

const rootReducer = combineReducers({
    getPortfolio,
    setPortfolio,
    getPosts,
    setPosts,
    sidenav,
    auth
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
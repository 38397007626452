import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Main, PortfolioList, PortfolioForm, PortfolioView, About, PostList, PostView, PostForm, Auth, Test } from './index';
import Navigation from '../components/Navigation';
import Copyright from '../components/Copyright';
import styled from 'styled-components';

function Routes(){
    return (
        <Router>
            <Navigation/>
            <Container>
                <Switch>
                    <Route exact path="/" component={Main} />
                    <Route path="/portfolio/view" component={PortfolioView} />
                    <Route path="/portfolio/form" component={PortfolioForm} />
                    <Route path={["/portfolio/list","/portfolio"]} component={PortfolioList} />
                    <Route path="/about" component={About} />
                    <Route path="/posts/view" component={PostView} />
                    <Route path="/posts/form" component={PostForm} />
                    <Route path={["/posts/list","/posts"]} component={PostList} />
                    <Route path="/auth" component={Auth} />
                    {/* <Route path="/test" component={Test} /> */}
                    <Redirect path="*" to="/" />
                </Switch>
            </Container>
            <Copyright/>
        </Router>
    )
}

const Container = styled.div`
    margin-top:60px;
`;

export default Routes;
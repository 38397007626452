import { createReducer } from "typesafe-actions";
import { SIDENAV } from "./actions";
import { SideNavState, SideNavAction } from "./types";

const initialState:SideNavState = {
    showState: true
};

const onSidenav = createReducer<SideNavState, SideNavAction>(initialState, {
    [SIDENAV]: (state) => {
        return {
            showState: !state.showState
        }
    }
});

export default onSidenav;
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../modules';
import { useCallback } from 'react';
import { setPosts, delPosts } from '../modules/setPosts';
import { firestore } from '../firebase/init';

function useSetPosts(){
    const form = useSelector((state:RootState) => state.setPosts);
    const dispatch = useDispatch();
    
    const onSetPosts = useCallback((title, content, type, id) => {
        if(id){
            firestore().collection("POSTS").doc(id).update({
                title: title,
                content: content,
                type: type
            }).then(() => {
                dispatch(setPosts(form));
                
                alert("수정되었습니다.");
                window.location.pathname = `/posts/view/${id}`;
            }).catch((err) => {
                if(err.code === 'permission-denied'){
                    alert('당신은 누구신가요...');
                }
            });
        } else {
            firestore().collection("POSTS").add({
                title: title,
                content: content,
                date: firestore.Timestamp.fromDate(new Date()),
                type: type,
                show: true,
                likes: []
            }).then(() => {
                dispatch(setPosts(form));
    
                alert("등록되었습니다.");
                window.location.pathname = "/posts/list";
    
            }).catch((err) => {
                console.log(err);
            });
        }

    }, [dispatch]);

    const onDelPosts = useCallback((id) => {
        firestore().collection("POSTS").doc(id).update({
            show: false
        }).then(() => {
            dispatch(delPosts(form));

            alert("삭제되었습니다.");
            window.location.pathname = "/posts/list";

        }).catch((err) => {
            console.log(err);
        });;
    }, [dispatch]);

    return {
        form,
        onSetPosts,
        onDelPosts
    }
}

export default useSetPosts;
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../modules';
import { useCallback } from 'react';
import { getPosts, likePosts } from '../modules/getPosts';
import { storage, firestore } from '../firebase/init';
import { FormatDate } from '../modules/common';

function useGetPosts(){
    const list = useSelector((state:RootState) => state.getPosts);
    const dispatch = useDispatch();
    
    const onGetPosts = useCallback(() => {

        let list = new Array();

        firestore().collection("POSTS").orderBy("date","desc").get().then((snapshot) => {
            snapshot.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                data.date = FormatDate(data.date.seconds);

                if(data.show) list.push(data);

            });


            console.log('Get Posts Success!');
            dispatch(getPosts(list));
        });

    }, [dispatch]);

    const onLikePosts = useCallback((id, guest, password) => {
        firestore().collection("POSTS").doc(id).get().then((doc) => {
            let data = doc.data();
            let likesArray = new Array();
            let pushData = {
                guest: guest,
                password : password
            }

            if(data) likesArray = data.likes;
            if(likesArray) likesArray.push(pushData);

            firestore().collection("POSTS").doc(id).get().then((doc) => {
                let data = doc.data();
                if(data){
                    const likes = data.likes.filter((like:any, idx:number) => {
                        return like.guest === guest;
                    });

                    if(likes.length > 0){
                        alert("이미 좋아요한 게스트입니다.");
                        return false;
                    }

                    firestore().collection("POSTS").doc(id).update({
                        likes:likesArray
                    }).then(() => {
                        dispatch(likePosts());
        
                        alert("좋아요");
                        onGetPosts();
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            });
        });
    }, [dispatch]);

    return {
        list,
        onGetPosts,
        onLikePosts
    }
}

export default useGetPosts;
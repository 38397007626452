import React, { useEffect, useState } from 'react';
import qs from 'qs';

//use hooks
import useGetPosts from '../../hooks/useGetPosts';
import useSetPosts from '../../hooks/useSetPosts';
import useSidenav from '../../hooks/useSidenav';
import useAuth from '../../hooks/useAuth';

//import components
import SideNav from '../SideNavgation';

//import modules
import { ContentTheme, TitleTheme } from '../../Theme';
import styled from 'styled-components';
import { BsTrashFill, BsTools } from 'react-icons/bs';
import { IoThumbsUpSharp } from 'react-icons/io5';

import { Link } from 'react-router-dom';

interface guestState {
    guestShow: boolean;
}

function View(props:any) {

    const { list, onGetPosts, onLikePosts } = useGetPosts();
    const { form, onDelPosts } = useSetPosts();
    const { show } = useSidenav();
    const { user } = useAuth();
    
    const [ guestShow, setGuestShow ] = useState<boolean>(false);
    const [ guest, setGuest ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');

    const query = qs.parse(props.location.search, {
        ignoreQueryPrefix: true 
     });

    useEffect(() => {
        onGetPosts();
    }, []);

    const onDelPostsHandler = () => {
        if(window.confirm("정말로 삭제하시겠습니까?")) onDelPosts(query.id);
    }

    const onGuestAuthHadler = () => {
        if(guestShow) setGuestShow(false);
        else setGuestShow(true);
        
    }

    const onLikePostsHandler = () => {
        if(!guest){
            alert('게스트를 입력하세요!');
            return false;
        }

        if(!password){
            alert('패스워드를 입력하세요!');
            return false;
        }

        onLikePosts(query.id, guest, password);
        setGuestShow(false);
    }

    return (
        <>
            <SideNav list={list} uri="posts" id={query.id?.toString()}/>
            {list ?
                list.filter((row) => {
                    return row.id === query.id;
                }).map((item, index) => {
                    return <Content id="content" key={index} {...show}>
                        <Title>
                            {item.title}
                            <Date>writed : {item.date}</Date>
                            <ButtonWrap>
                                <LikeButton onClick={onGuestAuthHadler}><IoThumbsUpSharp size={20} /> <span>{item.likes.length}</span></LikeButton>
                                <GuestAuth guestShow={guestShow}>
                                    <p>안녕하세요! 좋아요 등록을 위해 GUEST NAME, PASSWORD를 입력해주세요!</p>
                                    <input type="text" onChange={(e) => setGuest(e.target.value)} placeholder='GUEST NAME' />
                                    <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder='GUEST PASSWORD'/>
                                    <button onClick={onLikePostsHandler}>Like!</button>
                                </GuestAuth>
                                {user.status ? 
                                    <>
                                        <FormButton to={`/posts/form?id=${query.id}`}><BsTools size={20}/></FormButton>
                                        <DelButton onClick={onDelPostsHandler}><BsTrashFill size={20}/></DelButton>
                                    </>
                                : null}
                            </ButtonWrap>
                        </Title>
                        <TextContent>
                            {item.content.split("\n").map((char:string, idx:number) => {
                                return (
                                    <p key={idx}>{char}<br/></p>
                                )
                            })}
                        </TextContent>
                        <Reply>
                            {/* 리플 준비중입니다... */}
                        </Reply>
                    </Content>
                })
            : null}
        </>
    )
}

const Content = styled.div`
    ${ContentTheme}
`;

const Title = styled.h2`
    ${TitleTheme}
`;

const Date = styled.p`
    font-size: 0.6em;
    margin-top:0.5em;
    font-weight:normal;
`;

const TextContent = styled.div`
    font-size:1em;
    margin-bottom:5em;
`;

const Reply = styled.div`
    border-top:1px solid #383838;
    padding:1em 0;
`;

const ButtonWrap = styled.div`
    display:inline-block;
    position:absolute;
    bottom:1em;
    right:0;
    font-size:0.6em;
    font-weight:normal;
`;

const FormButton = styled(Link)`
    margin-left:1em;
`;

const DelButton = styled.button`
    color:inherit;
    margin-left:1em;
`;

const LikeButton = styled.button`
    color:inherit;

    & > span {
        vertical-align:bottom;
    }
`;

const GuestAuth = styled.div`
    display: ${(props:guestState) => props.guestShow ? 'block' : 'none'};
    position:absolute;
    top:100%;
    right:calc(100% + 1em);
    background:#252525;
    padding:1em;

    & > p {
        margin-bottom:1em;
        font-size:0.9em;
    }

    & > input {
        height:2.5em;
        padding:0 1em;
        border:0;
        background:#383838;
        color:#fff;
        font-family:inherit;
        margin-bottom:0.5em;
    }
    & > button {
        height:2.5em;
        text-align:center;
        color:#fff;
        width:100%;
        background:#007acc;
    }
`;

export default View;

import React, { useEffect } from 'react';

//import modules
import { ContentTheme, TitleTheme } from '../../Theme';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BsPlusSquareFill } from 'react-icons/bs';

//use hooks
import useGetPortfolio from '../../hooks/useGetPortfolio';
import useSidenav from '../../hooks/useSidenav';
import useAuth from '../../hooks/useAuth';

//import components
import ListItem from './ListItem';
import SideNav from '../SideNavgation';

function Portfolio(props:any) {

    const { list, onGetPortfolio } = useGetPortfolio();
    const { show } = useSidenav();
    const { user } = useAuth();

    useEffect(() => {
        onGetPortfolio();
    },[]);

    return (
        <>
            <SideNav list={list} uri="portfolio" />
            <Content id="content" {...show}>
                <Title>
                    Portfolio
                    <Length>Count : {list ? list.length : null}</Length>
                    {user.status ? <FormButton to="/portfolio/form"><BsPlusSquareFill /></FormButton> : null}
                </Title>
                <FlexBox>
                {list ? 
                    list.map((item, idx) => (
                        <ListItem key={idx} item={item} />
                    ))
                : null}
                </FlexBox>
            </Content>
        </>
    )
}

const Content = styled.div`
    ${ContentTheme}
`;

const FlexBox = styled.div`
    column-width:200px;
    column-gap: 1em;
`;

const Title = styled.h2`
    ${TitleTheme}
`;

const Length = styled.p`
    font-size: 0.6em;
    margin-top:0.5em;
    font-weight:normal;
`;

const FormButton = styled(Link)`
    position:absolute;
    right: 0;
    bottom:0.5em;
`;

export default Portfolio;

import React from 'react';

//use hooks
import useSidenav from '../hooks/useSidenav';

//import reducers
import { SideNavList } from '../modules/sidenav';

//import modules
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface SideNavListProps {
    list: SideNavList;
    uri: string;
    id?: string;
}

interface SideNavState {
    showState?: boolean;
    typeState?: string;
}

function SideNav({ list, uri, id }:SideNavListProps){

    const { show, onSidenavHandle } = useSidenav();

    return (
        <Nav id="sidebar" {...show}>
            <List>
                {list ? 
                    list.map((item, idx) => {
                        return (
                            <Item key={idx} to={`/${uri}/view?id=${item.id}`} className={item.id === id ? 'active' : ''}>
                                <Type typeState={item.type}>{item.type}</Type>
                                {item.title}
                            </Item>
                        )
                    })
                : null}
            </List>
            <Toggle onClick={onSidenavHandle} {...show}>리스트 닫기</Toggle>
        </Nav>
    )
}

const Nav = styled.div`
    position: fixed;
    top:60px;
    bottom:0;
    left:0;
    background:#252525;
    transition: all 0.5s ease-in-out;
    z-index:10;
    width: ${(props:SideNavState) => props.showState ? '250px' : '0px'};

    @media (max-width:768px){
        width: ${(props:SideNavState) => props.showState ? "40%" : "0px"};
    }
`;

const List = styled.div`
    width:100%;
    height:100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width:0.5em;
        background:transparent;
    }
    &::-webkit-scrollbar-thumb {
        background:#464647;
    }
`;

const Item = styled(Link)`
    display:block;
    padding:3px 10px;
    font-size:0.9em;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    &:hover, &:focus, &.active {
        background:#37373d;
    }
    &.active {
        color: #e2c08d;
    }
`;

const Type = styled.span`
    font-size:0.8em;
    margin-right:0.5em;
    color: ${(props:SideNavState) => props.typeState === "WEB"|| props.typeState === "NOTICE" ? '#cbcb3c' : props.typeState == "APP" || props.typeState === "TECH" ? '#e14770' : '#519AB6' };
`;

const Toggle = styled.button`
    position:absolute;
    right:0;
    top:50%;
    transform:translate(100%, -50%)${(props:SideNavState) => props.showState ? '' : 'rotate(180deg)'};
    width:1em;
    height:5em;
    background:#333333;
    text-indent:-9999px;
    overflow:hidden;
    &:after {
        content:"◀";
        display:block;
        color:#fff;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        text-indent:0;
        line-height:1;
        opacity:0.5;
        font-size:0.6em;
    }
`;

export default SideNav;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';

function Auth() {

    const [email, setEmail] = useState<String>("");
    const [password, setPassword] = useState<String>("");

    const { user, onAuthIn, onAuthOut, onAuthState } = useAuth();

    useEffect(() => {
        if(!user.status){
            onAuthState();
        }
    }, []);

    const onEmailHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const onPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const onAuthInHandler = () => {
        onAuthIn(email,password);
    }

    const onAuthOutHandler = () => {
        onAuthOut();
    }
    

    return (
        <Content id="content">
            {!user.status ? 
                <AuthWrap>
                    <Title>개발자님 어서오세요!</Title>
                    <Row>
                        <TextForm type="text" placeholder="E-MAIL" id="email" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onEmailHandler(e)} />
                    </Row>
                    <Row>
                        <TextForm type="password" placeholder="PASSWORD" id="password" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onPasswordHandler(e)} />
                    </Row>
                    <Row>
                        <Button onClick={onAuthInHandler}>LOGIN</Button>
                    </Row>
                </AuthWrap>
                :
                <AuthWrap>
                    <Title>개발자님이 로그인되어 있습니다.</Title>
                    <Row>
                        <Button onClick={onAuthOutHandler}>LOGOUT</Button>
                    </Row>
                </AuthWrap>
            }
            
        </Content>
    )
}

const Content = styled.div`
    padding: 30px;
    transition: all 0.5s ease-in-out;
`;

const AuthWrap = styled.div`
    max-width:500px;
    margin:0 auto;
`;

const Title = styled.h3`
    font-size:1.4em;
    color:#bababa;
    margin-bottom:1.5em;
    text-align:center;
`;

const Row = styled.div`
    margin-bottom:0.5em;
`;

const TextForm = styled.input`
    width:100%;
    height:3.5em;
    padding:0 1em;
    border:0;
    background:#383838;
    color:#fff;
    font-family:inherit;
    text-align:center;
`;

const Button = styled.button`
    font-family:inherit;
    color:#fff;
    text-align:center;
    width:100%;
    height:3.5em;
    background:#007acc;
`;

export default Auth;

import React from 'react';
import { PostItem } from '../../modules/getPosts';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface PostItemProps {
    item: PostItem
}

interface TypeState {
    typeof?: string;
}

function ListItem({ item }:PostItemProps) {

    const { id, title, content, date, likes, show, type } = item;

    return (
        <Item to={`/posts/view?id=${id}`}>
            <Inner>
                <Type typeof={type}><span>{type}</span></Type>
                <Title>{title}</Title>
                <Date>{date}</Date>
            </Inner>
        </Item>
    )
}

const Item = styled(Link)`
    display:block;
    width:50%;
    padding:0.5em;
    
    @media (max-width:768px){
        width:100%;
    }
`;

const Inner = styled.div`
    position:relative;
    overflow:hidden;
    padding:2em 2em 2em calc(4em + (1em * 1.2) + 2em);
    background:#252525;
`;

const Type = styled.div`
    display: table;
    position: absolute;
    top:0;
    left:0;
    width:calc(4em + (1em * 1.2));
    height:100%;
    background:#333333;
    text-align:center;
    color: ${(props:TypeState) => props.typeof === 'NOTICE' ? '#cbcb3c' : props.typeof === 'TECH' ? '#e14770' : '#519AB6'};

    & > span {
        display:table-cell;
        vertical-align: middle;
        font-weight:bold;
    }
`;

const Title = styled.p`
    font-size:1.1em;
    font-weight:bold;
    line-height:1;
    width:calc(100% - 80px);
`;

const Date = styled.span`
    font-size:0.9em;
    position:absolute;
    top:50%;
    right:2em;
    transform:translateY(-50%);
`;


export default ListItem;

import { createReducer } from "typesafe-actions";
import { AUTH_STATE, AUTH_IN, AUTH_OUT } from "./actions";
import { AuthState, AuthAction } from "./types";

const initialState:AuthState = {
    status: false,
    user: null
};

const onAuth = createReducer<AuthState, AuthAction>(initialState, {
    [AUTH_STATE]: (state, action) => {
        return {
            status: true,
            user: action.payload
        }
    },
    [AUTH_IN]: (state, action) => {
        return {
            status: true,
            user: action.payload
        }
    },
    [AUTH_OUT]: (state) => {
        return {
            status: false,
            user: null
        }
    }
});

export default onAuth;
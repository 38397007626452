import React, { useEffect } from 'react';

//import modules
import { ContentTheme, TitleTheme } from '../../Theme';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BsPlusSquareFill } from 'react-icons/bs';

//use hooks
import useGetPosts from '../../hooks/useGetPosts';
import useSidenav from '../../hooks/useSidenav';
import useAuth from '../../hooks/useAuth';

//import components
import ListItem from './ListItem';
import SideNav from '../SideNavgation';

function Posts(props:any){

    const { list, onGetPosts } = useGetPosts();
    const { show } = useSidenav();
    const { user } = useAuth();

    useEffect(() => {
        onGetPosts();
    }, []);

    return (
        <>
            <SideNav list={list} uri="posts" />
            <Content id="content" {...show}>
                <Title>
                    Posts
                    <Length>Count : {list ? list.length : null}</Length>
                    {user.status ? <FormButton to="/posts/form"><BsPlusSquareFill /></FormButton> : null}
                </Title>
                <FlexBox>
                {list ? 
                    list.map(item => {
                        return <ListItem key={item.id} item={item} />
                    })
                : null}
                </FlexBox>
            </Content>
        </>
    )
}

const Content = styled.div`
    ${ContentTheme}
`;

const FlexBox = styled.div`
    display:flex;
    flex-flow: row wrap;
`;

const Title = styled.h2`
    ${TitleTheme}
`;

const Length = styled.p`
    font-size: 0.6em;
    margin-top:0.5em;
    font-weight:normal;
`;

const FormButton = styled(Link)`
    position:absolute;
    right: 0;
    bottom:0.5em;   
`;

export default Posts;

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../modules';
import { useCallback } from 'react';
import { setPortfolio, delPortfolio } from '../modules/setPortfolio';
import { firestore, storage } from '../firebase/init';

function useSetPortfolio(){
    const form = useSelector((state:RootState) => state.setPortfolio);
    const dispatch = useDispatch();
    
    const onSetPortfolio = useCallback((title, year, type, image, url, content, id) => {
        const date = new Date();
        const yyyy = String(date.getFullYear());
        const mm = String(date.getMonth()+1).length < 2 ? "0"+String(date.getMonth()+1) : String(date.getMonth()+1);
        const dd = String(date.getDate()).length < 2 ? "0"+String(date.getDate()) : String(date.getDate());
        const hh = String(date.getHours()).length < 2 ? "0"+String(date.getHours()) : String(date.getHours());
        const ms = String(date.getMinutes()).length < 2 ? "0"+String(date.getMinutes()) : String(date.getMinutes());
        const ss = String(date.getSeconds()).length < 2 ? "0"+String(date.getSeconds()) : String(date.getSeconds());

        const yyyymmddhhmsss = yyyy+mm+dd+hh+ms+ss;
        
        if(id){

            if(image.constructor === File){ //기존 파일 변경시
                storage.ref().child(`portfolio/${yyyymmddhhmsss}`).put(image).then((snapshot) => {

                    storage.ref().child(`portfolio/${yyyymmddhhmsss}`).getDownloadURL().then((src) => {
                        firestore().collection("PORTFOLIO").doc(id).update({
                            title: title,
                            year: year,
                            type: type,
                            image: {
                                name: image.name,
                                src: src
                            },
                            show: true,
                            url: url,
                            content: content
                        }).then(() => {
                            dispatch(setPortfolio(form));
                            alert("수정되었습니다.");
                            window.location.pathname = "/portfolio/list";
                        }).catch((err) => {
                            if(err.code === 'permission-denied'){
                                alert('당신은 누구신가요...');
                            }
                        });
                    }).catch((err) => console.log(err));
                }).catch((err) => console.log(err));
            } else { //기존 파일 유지시
                firestore().collection("PORTFOLIO").doc(id).update({
                    title: title,
                    year: year,
                    type: type,
                    show: true,
                    url: url,
                    content: content
                }).then(() => {
                    dispatch(setPortfolio(form));
                    alert("수정되었습니다.");
                    window.location.pathname = "/portfolio/list";
                }).catch((err) => {
                    if(err.code === 'permission-denied'){
                        alert('당신은 누구신가요...');
                    }
                });
            }
            
        } else {
                storage.ref().child(`portfolio/${yyyymmddhhmsss}`).put(image).then((snapshot) => {

                    storage.ref().child(`portfolio/${yyyymmddhhmsss}`).getDownloadURL().then((src) => {
                        firestore().collection("PORTFOLIO").add({
                            title: title,
                            year: year,
                            date: firestore.Timestamp.fromDate(new Date()),
                            type: type,
                            image: {
                                name: image.name,
                                src: src
                            },
                            show: true,
                            url: url,
                            content: content
                        }).then(() => {
                            dispatch(setPortfolio(form));
                            alert("등록되었습니다.");
                            window.location.pathname = "/portfolio/list";
                        });
                    }).catch((err) => console.log(err));
                }).catch((err) => console.log(err));
        }

    }, [dispatch]);

    const onDelPortfolio = useCallback((id) => {
        firestore().collection("PORTFOLIO").doc(id).update({
            show: false
        }).then(() => {
            dispatch(delPortfolio(form));

            alert("삭제되었습니다.");
            window.location.pathname = "/portfolio/list";

        }).catch((err) => {
            console.log(err);
        });;
    }, [dispatch]);

    return {
        form,
        onSetPortfolio,
        onDelPortfolio
    }
}

export default useSetPortfolio;
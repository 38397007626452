import { css } from 'styled-components';

interface ShowState {
    showState?: boolean
}

export const ContentTheme:any = css`
    padding: 30px 30px 30px ${(props:ShowState) => props.showState ? "280px" : "30px"};
    transition: all 0.5s ease-in-out;
    @media (max-width:768px){
        padding: 30px 30px 30px ${(props:ShowState) => props.showState ? "calc(40% + 30px)" : "30px"};
    }
`;

export const TitleTheme:any = css`
    font-size:1.4em;
    margin-bottom:1em;
    padding-bottom:0.5em;
    border-bottom:1px solid #383838;
    position:relative;
`;
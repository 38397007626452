import React, { useState, useEffect } from 'react';
import qs from 'qs';

import useGetPortfolio from '../../hooks/useGetPortfolio';
import useSetPortfolio from '../../hooks/useSetPortfolio';

//import modules
import { ContentTheme, TitleTheme } from '../../Theme';
import styled from 'styled-components';

function Form(props:any) {

    const query = qs.parse(props.location.search, {
       ignoreQueryPrefix: true 
    });

    const { list, onGetPortfolio } = useGetPortfolio();
    const { form, onSetPortfolio } = useSetPortfolio();

    const [id, setId] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [type, setType] = useState<string>("WEB");
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [image, setImage] = useState<any>(null);
    const [url, setUrl] = useState<string>("");
    const [content, setContent] = useState<string>("");

    const onTitleHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const onTypeHandler = (e:React.ChangeEvent<HTMLSelectElement>) => {
        setType(e.target.value);
    }

    const onYearHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setYear(Number(e.target.value));
    }

    const onImageHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.files) setImage(e.target.files[0]);
        else setImage(null);
    }

    const onUrlHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
    }

    const onContentHandler = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(e.target.value);
    }

    const onSetPortfolioHandler = () => {
        onSetPortfolio(title, year, type, image, url, content, id);
    }

    const onFileDelHandler = () => {
        setImage(null);
    }

    const onFileSetHandler = () => {
        const fileform = document.getElementById('file');
        fileform?.click();
    }

    useEffect(() => {
        if (!list) {
            onGetPortfolio();
        } else {
            list.filter((row:any) => {
                return row.id === query.id;
            }).map((item:any, index:number) => {
                setId(item.id);
                setTitle(item.title);
                setType(item.type);
                setYear(item.year);
                setUrl(item.url);
                setContent(item.content);
                setImage(item.image);
            });
        }
    },[list]);

    return (
        <Content id="content">
            <Title>Portfolio Form</Title>
            <FormWrap>
                <Row>
                    <TextForm type="text" placeholder="제목" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onTitleHandler(e)} defaultValue={title} />
                </Row>
                <Row>
                    <SelectForm defaultValue={type} onChange={(e:React.ChangeEvent<HTMLSelectElement>) => onTypeHandler(e)}>
                        <option value="WEB">WEB</option>
                        <option value="APP">APP</option>
                    </SelectForm>
                </Row>
                <Row>
                    <TextForm type="number" placeholder="년도" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onYearHandler(e)} defaultValue={year}/>
                </Row>
                <Row>
                 <TextForm type="text" placeholder="웹사이트 주소" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onUrlHandler(e)} defaultValue={url}/>
                </Row>
                {image ? 
                    <Row>
                        <FileArea>
                            {image.name}
                            <button onClick={onFileDelHandler}>DELETE</button>
                        </FileArea>
                    </Row>
                : <Row>
                    <FileForm type="file" id="file" className="hidden" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onImageHandler(e)} />
                    <FileArea>
                        <button onClick={onFileSetHandler}>SELECT</button>
                    </FileArea>
                </Row>}
                <Row>
                    <TextArea placeholder="내용" onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => onContentHandler(e)} defaultValue={content}></TextArea>
                </Row>
                <Row>
                    <Button onClick={onSetPortfolioHandler}>SUBMIT</Button>
                </Row>
            </FormWrap>
            
        </Content>
    )
}

const Content = styled.div`
    ${ContentTheme}
`;

const Title = styled.h2`
    ${TitleTheme}
`;

const FormWrap = styled.div`
    max-width:500px;
`;

const Row = styled.div`
    margin-bottom:0.5em;
`;

const TextForm = styled.input`
    width:100%;
    height:3.5em;
    padding:0 1em;
    border:0;
    background:#383838;
    color:#fff;
    font-family:inherit;
`;

const FileForm = styled.input`
    width:100%;
    height:3.5em;
    padding:0 1em;
    border:0;
    background:#383838;
    color:#fff;
    font-family:inherit;
`;

const FileArea = styled.div`
    width:100%;
    height:3.5em;
    line-height:3.5em;
    padding:0 calc(7em * 0.8) 0 1em;
    border:0;
    background:#383838;
    color:#fff;
    font-family:inherit;
    position:relative;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;

    & > button {
        display:table;
        position:absolute;
        top:0.5em;
        right:0.5em;
        bottom:0.5em;
        background:#555;
        color:#eee;
        text-align:center;
        font-size:0.8em;
        width:6em;
    }
`;

const TextArea = styled.textarea`
    width:100%;
    height:15em;
    padding:1em;
    border:0;
    background:#383838;
    color:#fff;
    font-family:inherit;
    resize:none;
`;

const SelectForm = styled.select`
    width:100%;
    height:3.5em;
    padding:0 1em;
    border:0;
    background:#383838;
    color:#fff;
    font-family:inherit;
`;

const Button = styled.button`
    font-family:inherit;
    color:#fff;
    text-align:center;
    width:100%;
    height:3.5em;
    background:#007acc;
`;


export default Form;

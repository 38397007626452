import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../modules';
import { useCallback } from 'react';
import { getPortfolio } from '../modules/getPortfolio';
import { storage, database, firestore } from '../firebase/init';
import { FormatDate } from '../modules/common';

function useGetPortfolio(){
    const list = useSelector((state:RootState) => state.getPortfolio);
    const dispatch = useDispatch();
    
    const onGetPortfolio = useCallback(() => {

        let list = new Array();

        firestore().collection("PORTFOLIO").orderBy("date","desc").get().then((snapshot) => {
            snapshot.forEach(async doc => {
                let data = doc.data();

                data.id = doc.id;
                data.date = FormatDate(data.date.seconds);

                if(data.show){
                    list.push(data);
                }
            });
                
            dispatch(getPortfolio(list));
            
        }).catch((err) => console.log(err));

        // database.ref("list").orderByChild("year").on("value", async (snapshot) => {

        //     const getList = snapshot.val();
    
        //     const promiseList = getList ? Object.keys(getList).map(async (id) => {
        //         let image = null;
        //         await storage.ref('portfolio').child(getList[id].image).getDownloadURL().then((url) => {
        //             image = url;
        //         }).catch((err) => {
        //             console.error(err);
        //         });

        //         return {
        //             id,
        //             ...getList[id],
        //             image
        //         }
        //     }) : null;
    
        //     const portfolioList = new Array();
    
        //     if(promiseList){
    
        //         for(let i=0; i<promiseList.length; i++){
        //             await Promise.resolve(promiseList[i]).then((response) => {
        //                 portfolioList.push(response);
        //             }).catch((err) => {
        //                 console.error(err);
        //             })
        //         }
                
        //         portfolioList.reverse();

        //     }

        //     await dispatch(getPortfolio(portfolioList));
        // });

        
    }, [dispatch]);

    return {
        list,
        onGetPortfolio
    }
}

export default useGetPortfolio;
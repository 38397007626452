import React from 'react';
import styled from 'styled-components';
import { ContentTheme, TitleTheme } from '../../Theme';

function About() {

    return (
        <Content id="content">
            <Section>
                <Article>
                    <Title>About me</Title>
                    <Note>
                        <li><Tag>Name</Tag> Cho Seung-Hyeon / 조승현 <Tag>Name</Tag></li>
                        <li><Tag>Birthday</Tag> 1993-10-27 (+) <Tag>Birthday</Tag></li>
                        <li><Tag>Address</Tag> Busan, Republic of Korea / 부산광역시 <Tag>Address</Tag></li>
                    </Note>
                </Article>
                <Article>
                    <Title>Education</Title>
                    <Note>
                        <li>
                            <Tag>University</Tag>
                            <ul>
                                <li><Tag>Name</Tag> KIT / 경남정보대학교 <Tag>Name</Tag></li>
                                <li><Tag>Department</Tag> 산업디자인 <Tag>Department</Tag></li>
                                <li><Tag>Graduated</Tag> 2017 <Tag>Graduated</Tag></li>
                            </ul>
                            <Tag>University</Tag>
                        </li>
                    </Note>
                </Article>
                <Article>
                    <Title>Experience</Title>
                    <Note>
                        <li>
                            <Tag>Company</Tag>
                            <ul>
                                <li><Tag>Name</Tag> 앤시정보기술(주) <Tag>Name</Tag></li>
                                <li><Tag>Position</Tag> UI UX Developer / Frontend Developer <Tag>Position</Tag></li>
                                <li><Tag>Year</Tag> 2017 ~ <Tag>Year</Tag></li>
                                <li>
                                    <Tag>Projects</Tag>
                                    <ul>
                                        <li>창원대학교 홈페이지 유지보수
                                            <ul>
                                                <li>2017년 학생생활관 홈페이지 개편</li>
                                                <li>캠퍼스맵 Flash → Javascript 변환</li>
                                            </ul>
                                        </li>
                                        <li>국립부산과학관 홈페이지 유지보수
                                            <ul>
                                                <li>2017년 홈페이지 개편</li>
                                            </ul>
                                        </li>
                                        <li>남구도서관 홈페이지 유지보수</li>
                                        <li>대저생태공원 캠핑장 홈페이지 구축
                                            <ul>
                                                <li>2017년 홈페이지 개편</li>
                                            </ul>
                                        </li>
                                        <li>청소년여가문화 모바일 앱(이락) 개편</li>
                                        <li>부산도시공사 홈페이지 유지보수
                                            <ul>
                                                <li>2020년 홈페이지 개편</li>
                                                <li>부산도시공사역사관 홈페이지 구축</li>
                                            </ul>
                                        </li>
                                        <li>부산교통공사 정보자원시스템 통합유지보수
                                            <ul>
                                                <li>2018년 홈페이지 개편</li>
                                                <li>2020년 홈페이지 개편</li>
                                            </ul>
                                        </li>
                                        <li>㈜벡스코 정보시스템 개선 및 통합유지관리</li>
                                        <li>2017년 부산시청 홈페이지 유지보수</li>
                                        <li>2018년 부산시청 홈페이지 유지보수
                                            <ul>
                                                <li>행정전화번호부 구축</li>
                                                <li>부산시청 시장실 홈페이지 개편</li>
                                                <li>OK1번가 시즌1 홈페이지 구축</li>
                                                <li>도시건설 아고라 홈페이지 구축</li>
                                                <li>주민참여예산 홈페이지 구축</li>
                                            </ul>
                                        </li>
                                        <li>2019년 부산시청 홈페이지 유지보수
                                            <ul>
                                                <li>2018년 부산시청 홈페이지 개편</li>
                                                <li>2030엑스포 홈페이지 개편</li>
                                                <li>부산아이다가치키움 홈페이지 구축</li>
                                                <li>상수도사업본부 홈페이지 개편</li>
                                                <li>부산건축설계공모 홈페이지 구축</li>
                                                <li>도쿄올림픽 전지훈련 홈페이지 구축</li>
                                                <li>OK1번가 시즌2 홈페이지 구축</li>
                                                <li>청년정책플랫폼 홈페이지 개편</li>
                                                <li>시민청원 와글와글 구축</li>
                                                <li>인재개발원 홈페이지 개편</li>
                                            </ul>
                                        </li>
                                        <li>2020년 부산시청 홈페이지 유지보수
                                            <ul>
                                                <li>2020년 부산시청 홈페이지 개편</li>
                                                <li>협치부산 홈페이지 구축</li>
                                                <li>부산가족축제 홈페이지 구축</li>
                                                <li>코로나19 홈페이지 구축</li>
                                                <li>소상공인 민생지원 신청 홈페이지 구축</li>
                                                <li>마스크 판매처 홈페이지 구축</li>
                                                <li>일자리 홈페이지 개편</li>
                                                <li>다이내믹부산 홈페이지 개편</li>
                                                <li>부산대개조 홈페이지 구축</li>
                                                <li>하반기 코로나19 홈페이지 개편</li>
                                                <li>부산시 코로나19 확진자 통계 API 개발</li>
                                                <li>통합예약 홈페이지 개편</li>
                                                <li>민원120 홈페이지 개편</li>
                                            </ul>
                                        </li>
                                        <li>2021년 부산시청 홈페이지 유지보수
                                            <ul>
                                                <li>부산형 플러스 지원금 신청 사이트 구축</li>
                                                <li>취약노동자 가가격리 소득피해보상금 지원 사이트 구축</li>
                                                <li>부산시 선결제 이벤트 사이트 구축</li>
                                                <li>가덕도 신공항 홈페이지 구축</li>
                                            </ul>
                                        </li>
                                        <li>개인 및 외주 프로젝트
                                            <ul>
                                                <li>교육용 연극체험큐브 안드로이드 앱</li>
                                                <li>소셜벤처평가시스템 홈페이지 구축</li>
                                                <li>부산전통예술관 홈페이지 구축</li>
                                                <li>한국기업복지그룹 홈페이지 구축</li>
                                                <li>명례일반산업단지관리공단 홈페이지 구축</li>
                                                <li>이지스마트팩토리 홈페이지 구축</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <Tag>Projects</Tag>
                                </li>
                            </ul>
                            <Tag>Company</Tag>
                        </li>
                    </Note>
                </Article>
                <Article>
                    <Title>Ability</Title>
                    <Note>
                        <li>
                            <Tag>Certificate</Tag>
                            <ul>
                                <li><Tag>2020</Tag> 운전면허 2종 보통 <Tag>2020</Tag></li>
                                <li><Tag>2020</Tag> 정보처리기사 <Tag>2020</Tag></li>
                                <li><Tag>2017</Tag> 웹디자인기능사 <Tag>2017</Tag></li>
                                <li><Tag>2011</Tag> 정보기기운용기능사 <Tag>2011</Tag></li>
                                <li><Tag>2010</Tag> GTQ 포토샵 2급 <Tag>2010</Tag></li>
                            </ul>
                            <Tag>Certificate</Tag>
                        </li>
                        <li>
                            <Tag>Development</Tag>
                            <ul>
                                <li><Tag>1</Tag> HTML5 <Tag>1</Tag></li>
                                <li><Tag>2</Tag> CSS3 <Tag>2</Tag></li>
                                <li><Tag>3</Tag> JavaScript <Tag>3</Tag></li>
                                <li><Tag>4</Tag> jQuery <Tag>4</Tag></li>
                                <li><Tag>5</Tag> React <Tag>5</Tag></li>
                                <li><Tag>6</Tag> ReactNative <Tag>6</Tag></li>
                                <li><Tag>7</Tag> Spring(Java) <Tag>7</Tag></li>
                                <li><Tag>8</Tag> Express <Tag>8</Tag></li>
                                <li><Tag>9</Tag> TypeScript <Tag>9</Tag></li>
                            </ul>
                            <Tag>Development</Tag>
                        </li>
                        <li>
                            <Tag>DBMS</Tag>
                            <ul>
                                <li><Tag>1</Tag> MariaDB <Tag>1</Tag></li>
                                <li><Tag>2</Tag> MySQL <Tag>2</Tag></li>
                                <li><Tag>3</Tag> Cubrid <Tag>3</Tag></li>
                            </ul>
                            <Tag>DBMS</Tag>
                        </li>
                        <li>
                            <Tag>Tools</Tag>
                            <ul>
                                <li><Tag>1</Tag> Eclipse <Tag>1</Tag></li>
                                <li><Tag>2</Tag> Visual Studio Code <Tag>2</Tag></li>
                                <li><Tag>3</Tag> Android Studio <Tag>3</Tag></li>
                                <li><Tag>4</Tag> PhotoShop <Tag>4</Tag></li>
                                <li><Tag>5</Tag> Illustrator <Tag>5</Tag></li>
                            </ul>
                            <Tag>DBMS</Tag>
                        </li>
                    </Note>
                </Article>
            </Section>
        </Content>
    )
}

const Content = styled.div`
    ${ContentTheme}
`;

const Section = styled.section`
    display:flex;
    justify-content: space-between;
    flex-flow: row wrap;
`;

const Article = styled.article`
    width:calc(50% - 1em);
    margin-bottom:2em;

    @media all and (max-width:768px){
        width:100%;
    }
`;

const Title = styled.h2`
    ${TitleTheme}
`;

const Note = styled.ul`
    background:#252525;
    padding:1em;

    & > li {
        margin-bottom: 0.5em;

        &:last-child {
            margin-bottom:0;
        }

        & > ul {
            margin-left:2em;

            & > li {
                & > ul {
                    margin-left:2em;

                    & > li {
                        &:before {
                            content:"- ";
                        }

                        & > ul {
                            margin-left:1em;
                            margin-bottom:0.5em;
                            & > li {
                                font-size: 0.8em;

                                &:before {
                                    content:"· ";
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Tag = styled.span`
    color:#42c9b0;

    &:before {
        content: '<';
        color:#808080;
    }

    &:after {
        content: '>';
        color:#808080;
    }

    &:last-of-type {
        &:before {
            content: '</';
        }
    }
`;

export default About;

import React, { useState, useEffect } from 'react';
import qs from 'qs';

import useGetPosts from '../../hooks/useGetPosts';
import useSetPosts from '../../hooks/useSetPosts';

//import modules
import { ContentTheme, TitleTheme } from '../../Theme';
import styled from 'styled-components';

function Form(props:any) {

    const query = qs.parse(props.location.search, {
       ignoreQueryPrefix: true 
    });

    const { list, onGetPosts } = useGetPosts();
    const { form, onSetPosts } = useSetPosts();

    const [title, setTitle] = useState<string>("");
    const [type, setType] = useState<string>("ETC");
    const [content, setContent] = useState<string>("");
    const [id, setId] = useState<string>("");

    const onTitleHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const onTypeHandler = (e:React.ChangeEvent<HTMLSelectElement>) => {
        setType(e.target.value);
    }

    const onContentHandler = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(e.target.value);
    }

    const onSetPostsHandler = () => {
        onSetPosts(title, content, type, id);
    }

    useEffect(() => {
        if (!list) {
            onGetPosts();
        } else {
            list.filter((row:any) => {
                return row.id === query.id
            }).map((item:any, index:number) => {
                setId(item.id);
                setTitle(item.title);
                setContent(item.content);
                setType(item.type);
            });
        }
    }, [list]);

    return (
        <Content id="content">
            <Title>Posts Form</Title>
            <FormWrap>
                <Row>
                    <TextForm type="text" placeholder="제목" onChange={(e:React.ChangeEvent<HTMLInputElement>) => onTitleHandler(e)} defaultValue={title} />
                </Row>
                <Row>
                    <SelectForm value={type} onChange={(e:React.ChangeEvent<HTMLSelectElement>) => onTypeHandler(e)}>
                        <option value="ETC">ETC</option>
                        <option value="NOTICE">NOTICE</option>
                        <option value="TECH">TECH</option>
                    </SelectForm>
                </Row>
                <Row>
                    <TextArea placeholder="내용" onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => onContentHandler(e)} defaultValue={content}></TextArea>
                </Row>
                <Row>
                    <Button onClick={onSetPostsHandler}>SUBMIT</Button>
                </Row>
            </FormWrap>
            
        </Content>
    )
}

const Content = styled.div`
    ${ContentTheme}
`;

const Title = styled.h2`
    ${TitleTheme}
`;

const FormWrap = styled.div`
    max-width:500px;
`;

const Row = styled.div`
    margin-bottom:0.5em;
`;

const TextForm = styled.input`
    width:100%;
    height:3.5em;
    padding:0 1em;
    border:0;
    background:#383838;
    color:#fff;
    font-family:inherit;
`;

const TextArea = styled.textarea`
    width:100%;
    height:15em;
    padding:1em;
    border:0;
    background:#383838;
    color:#fff;
    font-family:inherit;
    resize:none;
`;

const SelectForm = styled.select`
    width:100%;
    height:3.5em;
    padding:0 1em;
    border:0;
    background:#383838;
    color:#fff;
    font-family:inherit;
`;

const Button = styled.button`
    font-family:inherit;
    color:#fff;
    text-align:center;
    width:100%;
    height:3.5em;
    background:#007acc;
`;


export default Form;

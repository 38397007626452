import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../modules';
import { useCallback } from 'react';
import { getAuthState, setAuthIn, setAuthOut } from '../modules/auth';
import { auth } from '../firebase/init';

function useAuth(){
    const user = useSelector((state:RootState) => state.auth);
    const dispatch = useDispatch();

    const onAuthIn = useCallback((email, password) => {
        auth().signInWithEmailAndPassword(email, password)
        .then((data:any) => {
            dispatch(setAuthIn(data.user));
        })
        .catch((err) => {
            let code = err.code;
            switch(code){
                case "auth/wrong-password" :  alert("패스워드 모르시죠?"); break;
                case "auth/invalid-email" :  alert("이메일 형식이 아닌걸요?"); break;
                case "auth/user-not-found" :  alert("당신은 누구신가요?"); break;
                case "auth/too-many-requests" :  alert("잠깐 쉬었다 하시죠.."); break;
                default : alert("무슨 에러죠?");
            }
        });
    }, [dispatch]);

    const onAuthOut = useCallback(() => {
        auth().signOut()
        .then(() => {
            alert("안녕히가세요. 또봐요.")
            dispatch(setAuthOut());
        });
    }, [dispatch]);

    const onAuthState = useCallback(() => {
        auth().onAuthStateChanged((data) => {
            if(data !== null){
                dispatch(getAuthState(data));
            }
        });
    }, [dispatch]);

    return {
        user,
        onAuthIn,
        onAuthOut,
        onAuthState
    }
} 

export default useAuth;
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../modules';
import { useCallback } from 'react';
import { onSidenav } from '../modules/sidenav';

function useSidenav(){
    const show = useSelector((state:RootState) => state.sidenav);

    const dispatch = useDispatch();

    const onSidenavHandle = useCallback(() => {
        console.log(show)
        dispatch(onSidenav());
    }, [dispatch]);

    return {
        show,
        onSidenavHandle
    }
}

export default useSidenav;
import { createReducer } from "typesafe-actions";
import { SET_PORTFOLIO, DEL_PORTFOLIO } from "./actions";
import { PortfolioState, PortfolioAction } from "./types";

const initialState:PortfolioState = {
    id: "",
    year: Number(new Date().getFullYear()),
    title: "",
    date: "",
    type: "WEB",
    image: {},
    show: true,
    url: ""
};

const onSetPortfolio = createReducer<PortfolioState, PortfolioAction>(initialState, {
    [SET_PORTFOLIO]: (state, action) => (
        action.payload
    ),
    [DEL_PORTFOLIO]: (state, action) => (
        action.payload
    )
});

export default onSetPortfolio;